import Vue from 'vue'
import prettyBytes from 'pretty-bytes'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
class listConfig {
  protected vm?: Vue
  protected listKey?: string

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.vm = vm
    this.listKey = listKey

    return {
      pageTitle: 'photo_manager',
      search: 'photo.search_keyword',
      filter: {
        type: {
          label: 'photo.data.type',
          type: 'text',
        },
        extension: {
          label: 'photo.data.extension',
          type: 'text',
        },
        created_at: {
          label: 'data.created_at',
          type: 'date',
        },
      },
      selectedData: row => ({
        id: row.id,
        name: row.name,
        photo: row,
      }),
      sort: [
        { label: 'data.created_at', key: 'created_at' },
        { label: 'data.file_size', key: 'size' },
      ],
      card: {
        title: row => row.name,
        subtitle: row => row.name,
        avatarPhoto: row => row,
      },
      table: {
        data: <TableDataType>[
          {
            key: 'photo',
            label: 'data.photo',
            type: 'photo',
            photo: row => row,
          },
          {
            key: 'name',
            label: 'photo.data.name',
            type: 'text',
          },
          {
            key: 'source_name',
            copyable: true,
            label: 'photo.data.source_name',
            type: 'text',
          },
          {
            key: 'type',
            label: 'photo.data.type',
            type: 'text',
          },
          {
            key: 'size',
            label: 'data.file_size',
            type: 'text',
            text: row => prettyBytes(row.size),
          },
          {
            key: 'uid',
            label: 'UID',
            copyable: true,
            type: 'text',
          },
          {
            key: 'created_at',
            label: 'data.created_at',
            type: 'time',
          },
        ],
      },
      batch: {
        delete: {
          targetLabel: row => row.name,
        },
      },
      dataAction: {
        download: {
          component: () => import('modules/base/views/photoList/download.vue'),
        },
      },
    }

  }
}

export default new listConfig()
