<template>
  <eagle-list
    @updatePopupSelectData="updatePopupSelectData"
    :list-key="listKey"
  ></eagle-list>
</template>

<script>
import listMixin from '@/components/list/mixins/listMixin'
import listConfig from './photoListConfig'
export default {
  mixins: [listMixin],
  data: () => ({
    listKey: 'photo-list-selector',
    meta: {},
  }),
  methods: {
    updatePopupSelectData(data) {
      this.$emit('updateData', data)
    },
    async beforeIndex() {
      await Promise.all([])
    },
    async indexApi(params) {
      return await this.$api.collection.photoApi.index(params)
    },
    getListConfig() {
      return listConfig
    },
  },
}
</script>

<style lang="sass" type="text/sass" scoped></style>
