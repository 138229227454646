import Vue from 'vue'
import { EagleListConfigInterface, TableDataType } from '@/components/list/types/list'
import photoListConfig from 'modules/base/views/photoListConfig'
class listConfig {
  protected vm?: Vue
  protected listKey?: string
  protected standardPhotoListConfig?: EagleListConfigInterface

  get(vm: Vue, listKey: string) : EagleListConfigInterface {
    this.standardPhotoListConfig = photoListConfig.get(vm, listKey)
    this.vm = vm
    this.listKey = listKey

    return {
      search: this.standardPhotoListConfig.search,
      filter: this.standardPhotoListConfig.filter,
      selectedData: this.standardPhotoListConfig.selectedData,
      sort: this.standardPhotoListConfig.sort,
      table: this.standardPhotoListConfig.table,
    }
  }
}

export default new listConfig()
